import MicroModal from 'micromodal';

MicroModal.init({
   disableScroll: true,
   openClass: 'is-open',
	onShow: modal => {
		var video = modal.querySelector('iframe[src*="www.youtube.com"], iframe[src*="player.vimeo.com"], video');
		if (!video) return;
		if (video.tagName.toLowerCase() === 'video') {
			video.play();
			return;
		}
		video.src = video.src + (video.src.indexOf('?') < 0 ? '?' : '&') + 'autoplay=1';
	},
	onClose: modal => {
		var video = modal.querySelector('iframe[src*="www.youtube.com"], iframe[src*="player.vimeo.com"], video');
		video.src = video.src.replace('&autoplay=1', '').replace('?autoplay=1', '');
	}
});
